var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: { title: " ", active: _vm.selectAuthTypeModal },
      on: {
        "update:active": function ($event) {
          _vm.selectAuthTypeModal = $event
        },
      },
    },
    [
      _c(
        "vs-row",
        { staticClass: "items-center pt-4" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-w": "12",
                "vs-justify": "center",
              },
            },
            [
              _c("h5", [
                _vm._v("Você deseja receber o token de autenticação por:"),
              ]),
            ]
          ),
          _c(
            "vs-col",
            {
              staticClass: "py-5",
              attrs: {
                "vs-type": "flex",
                "vs-w": "12",
                "vs-justify": "center",
              },
            },
            [
              _c(
                "vs-radio",
                {
                  staticClass: "pr-5",
                  attrs: { "vs-name": "authOption", "vs-value": 0 },
                  model: {
                    value: _vm.authOption,
                    callback: function ($$v) {
                      _vm.authOption = $$v
                    },
                    expression: "authOption",
                  },
                },
                [_vm._v("\n        SMS\n      ")]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "pl-5",
                  attrs: { "vs-name": "authOption", "vs-value": 1 },
                  model: {
                    value: _vm.authOption,
                    callback: function ($$v) {
                      _vm.authOption = $$v
                    },
                    expression: "authOption",
                  },
                },
                [_vm._v("\n        E-mail\n      ")]
              ),
            ],
            1
          ),
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-w": "12", "vs-justify": "end" } },
            [
              _c(
                "vs-button",
                {
                  staticClass: "self-end mt-2 float-right",
                  attrs: { size: "large", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitGenerate()
                    },
                  },
                },
                [_vm._v("\n        Prosseguir\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }