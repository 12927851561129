<template>
  <vs-popup title=" " :active.sync="selectAuthTypeModal">
    <vs-row class="items-center pt-4">
      <vs-col vs-type="flex" vs-w="12" vs-justify="center">
        <h5>Você deseja receber o token de autenticação por:</h5>
      </vs-col>

      <vs-col class="py-5" vs-type="flex" vs-w="12" vs-justify="center">
        <vs-radio
          class="pr-5"
          v-model="authOption"
          vs-name="authOption"
          :vs-value="0"
        >
          SMS
        </vs-radio>

        <vs-radio
          class="pl-5"
          v-model="authOption"
          vs-name="authOption"
          :vs-value="1"
        >
          E-mail
        </vs-radio>
      </vs-col>

      <vs-col vs-type="flex" vs-w="12" vs-justify="end">
        <vs-button
          @click="submitGenerate()"
          class="self-end mt-2 float-right"
          size="large"
          color="primary"
        >
          Prosseguir
        </vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    showModal: { type: Boolean, default: false },
    dados: { type: Object, default: () => {} }
  },
  data() {
    return {
      authOption: 0
    };
  },
  computed: {
    selectAuthTypeModal: {
      get() {
        return this.showModal;
      },

      set(value) {
        this.$emit("closeModal");
      }
    }
  },
  methods: {
    ...mapActions("auth", ["generateToken"]),
    ...mapMutations("auth", ["setDadosGenerateToken"]),

    submitGenerate() {
      this.$vs.loading();
      let payload = {
        Username: this.dados.email,
        Password: this.dados.password,
        Method: this.authOption
      };

      try {
        return this.generateToken(payload)
          .then(response => {
            this.setDadosGenerateToken({
              ...response.data,
              Username: this.dados.email,
              Password: this.dados.password
            });

            if (this.$route.name != "valida-token-acesso")
              this.$router.push({
                name: "valida-token-acesso"
              });
            else this.selectAuthTypeModal = false;
          })
          .catch(errors => {
            this.$onpoint.errorModal(
              ((errors.response || {}).data || {}).error_description ||
                "Não foi possível realizar o Login."
            );
          })
          .finally(() => {
            this.$vs.loading.close();
            this.selectAuthTypeModal = false;
          });
      } catch (ex) {
        this.$vs.loading.close();
        this.selectAuthTypeModal = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
